import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SurgicalOrthodontics from "../../components/service-items/surgical-orthodontics";
import { colors } from "../../styles/variables";

const SurgicalOrthodonticsPage = () => (
  <Layout bgColor={colors.light}>
    <SEO title="Surgical Orthodontics" />
    <SurgicalOrthodontics />
  </Layout>
);

export default SurgicalOrthodonticsPage;
