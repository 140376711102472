import React from "react";
import { Link } from "gatsby";
import Image from "./Images";
import {
  Container,
  ImageContainer,
  ContentsContainer,
  Title,
  SubTitle,
  Text,
  Wrapper,
  SecondWrapper,
  StyledImg,
} from "./styles";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import BannerImages from "../BannerImgs";

const SurgicalOrthodontics = () => {
  const image = Image();
  return (
    <>
      <Breadcrumb pageTitle="">
        <BreadcrumbItem Link="/what-we-do" Text="What We Do" />
        <BreadcrumbItem
          Link="/what-we-do/surgical-orthodontics"
          Text="Surgical Orthodontics"
        />
      </Breadcrumb>
      <BannerImages imagesrc="surgicalOrthodontics" />{" "}
      <PageContainer>
        <Container>
          <Title>Surgical Orthodontics</Title>
          <Wrapper>
            <ContentsContainer>
              <Text>
                In some cases, orthodontics alone cannot fix the underlying
                problems and Gippsland Orthodontics have to work with other
                speciality team members to obtain an ideal outcome. When the
                lower and upper jaws are not aligned properly, they can
                potentially impact a patient in many ways. These may include the
                ability to function and bite properly, the facial appearance and
                in some patients, the inability to breath correctly.
              </Text>
              <Text>
                When our patients suffer from skeletal discrepancies, either
                from existing concerns or emergencies due to trauma suffered by
                the face, or breathing issues like sleep apnoea, at Gippsland
                Orthodontics, we will refer you to an Oral and Maxillofacial
                surgeon. In some scenarios, we may also refer you to an Ear,
                Nose and Throat (ENT) specialist.
              </Text>
              <Text>
                An Oral and Maxillofacial surgeon is a dental specialist who
                treats a wide variety of issues regarding the jaw, face, neck,
                head and soft tissues inside the mouth.
              </Text>
              <Text>
                For the most ideal orthodontic results, Gippsland Orthodontics
                will work as team with the local Oral and Maxillofacial surgeon
                to coordinate a treatment plan that is ideal for you. It may
                involve the jaws being properly positioned prior to beginning
                your orthodontic treatment. Alternatively, and in most cases,
                the surgery to correct the jaws may be completed during the
                orthodontic treatment.
              </Text>
              <Text>
                There are several different types of surgeries that may be used
                to correct the above concerns with bites, jaws, facial
                appearance and sleeping problems. These may include:
              </Text>
            </ContentsContainer>
            <ImageContainer>
              <StyledImg fluid={image.smileMan} />
            </ImageContainer>
          </Wrapper>
          <Wrapper>
            <>
              <SubTitle>Mandibular Advancement Surgery</SubTitle>

              <Text>
                When the upper and lower jaws need repositioning into the
                correct position relative to one another, the Mandibular
                Advancement Surgery can help. This surgery often involves moving
                the lower jaw forward to match the top jaw which, with
                orthodontic, aims to achieve a better bite and facial
                appearance.
              </Text>

              <SubTitle>
                Maxillary Advancement and/or Mandibular Set Back
              </SubTitle>

              <Text>
                This surgery is used to treat a Class III Malocclusion which is
                when the lower jaw sits forward to the top jaw (some may term it
                an ‘underbite’). It occurs more when there is limited upper jaw
                growth or trauma to the upper jaw, and also may occur when there
                is excessive lower jaw growth.
              </Text>
              <Text>
                Common surgery to treat a Class III Malocclusion involves a
                maxillary advancement where the upper jaw is shifted forwards.
                Alongside this, the mandible is set back, or the lower jaw is
                moved back, to get the bottom and top jaws to fit together
                properly. People who have this surgery often find an improvement
                in their facial profile.
              </Text>
            </>
          </Wrapper>
          <Wrapper>
            <>
              <SubTitle>Maxillary Impaction (Gummy Smile)</SubTitle>
              <Text>
                Gummy smiles are quite often caused by the top jaw having excess
                vertical (up and down) growth, which causes a patient to have a
                ‘longer’ face. The surgery to reduce the gummy smile, is to
                remove a part of the upper jaw, and then move the upper jaw
                upwards to reduce the visible gum. There are other ways to
                potentially fix a gummy smile and all options must be discussed
                with Gippsland Orthodontics prior to deciding if surgery is the
                right option for you.
              </Text>
              <SubTitle>
                Surgically Assisted Maxillary Expansion (S.A.M.E)
              </SubTitle>
              <Text>
                Some people have an upper jaw that is too narrow and requires
                expansion to create room for teeth or orthodontic treatment.
                Ideally, orthodontists will aim to get expansion during the
                adolescent years, however, when a patient has finished growing
                it may require surgery to expand the upper jaw. Also referred to
                as upper jaw expansion surgery, the goal is to increase the size
                of the upper jaw.
              </Text>
            </>
          </Wrapper>
          <SecondWrapper>
            <ImageContainer>
              <StyledImg fluid={image.smileGirl2} />
            </ImageContainer>
            <ContentsContainer>
              <SubTitle>Chin Advancement (Genioplasty)</SubTitle>
              <Text>
                The common reason for having a genioplasty is the feeling of a
                patient being unhappy with their chin’s appearance; however,
                this surgery is commonly paired with jaw surgery to improve the
                position of the lower and upper jaw. This surgery is also known
                as a chin augmentation and is intended to improve the appearance
                of the jaw and chin.
              </Text>
              <SubTitle>Sleep Apnoea Surgery</SubTitle>
              <Text>
                Sleep apnoea is a condition in which your breathing stops
                periodically during sleep. People who suffer sleep apnoea will
                often suffer from drowsiness, tiredness, difficulty in sleeping,
                snoring, headaches and falling asleep unintentionally during the
                day.
              </Text>
              <Text>
                There are three categories of sleep apnoea; obstructive sleep
                apnoea (OSA) being the most common, the least common is central
                sleep apnoea (CSA), and lastly, a combination of both. Often a
                patient will require a sleep study prior to diagnosis and
                management of sleep apnoea.
              </Text>
              <Text>
                A proper diagnosis is required prior to treating patients with
                sleep apnoea and if appropriate, in some cases a combined
                orthodontic and surgical approach to sleep apnoea may be
                appropriate. There are different types of surgery available to
                patients and each is specific to the position of the teeth and
                jaws. If you have any questions or suffer from any of the above
                jaw, health or medical issues, please reach out and book a
                consultation with the Gippsland Orthodontic team{" "}
                <Link to={`/contact`}>here</Link>.
              </Text>
            </ContentsContainer>
          </SecondWrapper>
        </Container>
      </PageContainer>
    </>
  );
};

export default SurgicalOrthodontics;
